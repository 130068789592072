<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Form</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group pt-2">
                                <label for="inputEmail">Email</label>
                                <input id="inputEmail" class="form-control" placeholder="Text Input" type="email">
                            </div>
                            <div class="form-group">
                                <label for="inputPassword">Password</label>
                                <input id="inputPassword" class="form-control" placeholder="••••" type="password">
                                <span class="font-12 text-secondary">Lorem ipsum dolor sit amet, consetetur elitr, sed diam, nonumy eimod</span>
                            </div>
                            <div class="row pt-3">
                                <div class="col-lg-6 pb-4 pb-lg-0">
                                    <div class="be-checkbox custom-control custom-checkbox">
                                        <input id="check1" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label" for="check1">Remember me</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <button class="btn btn-space btn-secondary mr-1">Cancel</button>
                                    <button class="btn btn-space btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Horizontal Form</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row mt-2">
                                <label class="col-3 col-lg-2 col-form-label text-right" for="inputEmail2">Email</label>
                                <div class="col-9 col-lg-10">
                                    <input id="inputEmail2" class="form-control" placeholder="Email" type="email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-lg-2 col-form-label text-right"
                                       for="inputPassword2">Password</label>
                                <div class="col-9 col-lg-10">
                                    <input id="inputPassword2" class="form-control" placeholder="••••" type="password">
                                </div>
                            </div>
                            <div class="row pt-3 mt-1">
                                <div class="col-sm-6 col-lg-6 pb-4 pb-lg-0">
                                    <div class="be-checkbox custom-control custom-checkbox">
                                        <input id="check2" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label" for="check2">Remember me</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <button class="btn btn-space btn-secondary mr-1">Cancel</button>
                                    <button class="btn btn-space btn-primary" type="submit">Register</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Elements</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label for="inputText3">Input Text</label>
                                <input id="inputText3" class="form-control" placeholder="Text Input" type="text">
                            </div>
                            <div class="form-group">
                                <label for="inputPassword3">Password</label>
                                <input id="inputPassword3" class="form-control" placeholder="••••" type="password">
                            </div>

                            <div class="form-group">
                                <label for="inputPlaceholder4">Helper text</label>

                                <input id="inputPlaceholder4" class="form-control" placeholder="••••" type="password">
                                <span class="font-12 form-text text-muted">
                                    Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                                </span>

                            </div>
                            <div class="form-group">
                                <label for="inputDisabled3">Disabled Input</label>
                                <input id="inputDisabled3" class="form-control" disabled="disabled" placeholder="Text Input"
                                       type="text">
                            </div>
                            <div class="form-group">
                                <label for="inputReadonly3">Readonly Input</label>
                                <input id="inputReadonly3" class="form-control" readonly="readonly" type="text"
                                       value="Text Input">
                            </div>
                            <div class="form-group">
                                <label for="inputTextarea3">Textarea</label>
                                <textarea id="inputTextarea3" class="form-control"
                                          placeholder="Start typing here..."></textarea>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input File</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="customFile">Button File
                                    Input</label>
                                <div class="col-12 col-sm-9">
                                    <div class="custom-file">
                                        <input id="customFile" class="custom-file-input" type="file">
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Custom Button File
                                    Input</label>
                                <div class="col-12 col-sm-9">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend"><span id="inputGroupFileAddon01"
                                                                               class="input-group-text">Upload</span>
                                        </div>
                                        <div class="custom-file">
                                            <input id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" class="custom-file-input"
                                                   type="file">
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="custom-file">
                                            <input id="inputGroupFile02" class="custom-file-input" type="file">
                                            <label aria-describedby="inputGroupFileAddon02" class="custom-file-label"
                                                   for="inputGroupFile02">Choose file</label>
                                        </div>
                                        <div class="input-group-append">
                                            <div id="inputGroupFileAddon02" class="btn btn-primary">Upload</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Validation States</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label for="inputText13">Active</label>
                                <input id="inputText13" class="form-control focus"
                                       placeholder="Text Input" type="text">
                            </div>

                            <div class="form-group">
                                <label for="inputText23">Danger</label>
                                <input id="inputText23" class="form-control is-invalid"
                                       placeholder="Text Input" type="text">
                            </div>

                            <div class="form-group">
                                <label for="inputText33">Success</label>
                                <input id="inputText33" class="form-control is-valid"
                                       placeholder="Text Input" type="text">
                            </div>

                            <div class="form-group">
                                <label for="inputText43">Warning</label>
                                <input id="inputText43" class="form-control is-warning"
                                       placeholder="Text Input" type="text">
                            </div>

                            <div class="form-group">
                                <label for="inputDisabled13">Disabled Input</label>
                                <input id="inputDisabled13" class="form-control" disabled="disabled" placeholder="Text Input"
                                       type="text">
                            </div>

                            <div class="form-group">
                                <label for="inputPassword13">Password / Danger</label>
                                <input id="inputPassword13" class="form-control is-invalid" placeholder="••••"
                                       type="password">
                                <span class="font-12 form-text text-muted">
                                    Lorem ipsum dolor sit amet, sonetetur elitr, sed diam nonumy eimod
                                </span>
                            </div>

                            <div class="form-group">
                                <label for="inputPassword23">Password / Success</label>
                                <input id="inputPassword23" class="form-control is-valid" placeholder="••••"
                                       type="password">
                                <span class="font-12 form-text text-muted">
                                    Lorem ipsum dolor sit amet, sonetetur elitr, sed diam nonumy eimod
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input Groups</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Button File Input</label>
                                <div class="col-12 col-sm-8 col-lg-9">
                                    <div class="input-group mb-3"><span class="input-group-prepend"><span
                                        class="input-group-text">@</span></span>
                                        <input class="form-control" placeholder="Username" type="text">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Checkbox &amp; Radio</label>
                                <div class="col-12 col-sm-8 col-lg-9">
                                    <div class="form-row">

                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="radio">
                                                    </div>
                                                </div>
                                                <input class="form-control" placeholder="Radio" type="text">
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox">
                                                    </div>
                                                </div>
                                                <input class="form-control" placeholder="Check" type="text">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Input Text</label>
                                <div class="col-12 col-sm-8 col-lg-9">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                            <span class="input-group-text">0.00</span>
                                        </div>
                                        <input aria-label="Amount (to the nearest dollar)" class="form-control"
                                               type="text">
                                    </div>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input aria-label="Amount (to the nearest dollar)" class="form-control"
                                               type="text">
                                        <div class="input-group-append">
                                            <span class="input-group-text">0.00</span>
                                        </div>
                                    </div>

                                    <div class="input-group">
                                        <input aria-label="Amount (to the nearest dollar)" class="form-control"
                                               type="text">
                                        <div class="input-group-append">
                                            <span class="input-group-text">$</span>
                                            <span class="input-group-text">0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Button Addons</label>
                                <div class="col-12 col-sm-8 col-lg-9">
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button">Go!</button>
                                        </div>
                                    </div>

                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append be-addon">
                                            <button class="btn btn-primary" tabindex="-1" type="button">Button</button>
                                            <button class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                                    data-toggle="dropdown" tabindex="-1" type="button"><span
                                                class="sr-only">Toggle Dropdown</span></button>
                                            <div class="dropdown-menu"><a class="dropdown-item" href="#">Action</a><a
                                                class="dropdown-item" href="#">Another action</a><a
                                                class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append be-addon">
                                            <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
                                                    type="button">Button
                                            </button>
                                            <div class="dropdown-menu"><a class="dropdown-item" href="#">Action</a><a
                                                class="dropdown-item" href="#">Another action</a><a
                                                class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Range Element</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3" for="customRange1">Example range</label>
                                <div class="col-12 col-sm-9">
                                    <input id="customRange1" class="custom-range" type="range">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3" for="customRange2">Example range</label>
                                <div class="col-12 col-sm-9">
                                    <input id="customRange2" class="custom-range" max="5" min="0" type="range">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input Groups</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Checkbox</label>
                                <div class="col-12 col-sm-8 col-lg-9 mt-1">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input id="check3" checked="" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label font-14" for="check3">Checkbox /
                                            Selected</label>
                                    </div>
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input id="check4" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label font-14" for="check4">Checkbox /
                                            Default</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input id="check6" class="custom-control-input" disabled type="checkbox">
                                        <label class="custom-control-label font-14" for="check6">Checkbox /
                                            Disabled</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Checkbox Inline</label>
                                <div class="col-12 col-sm-8 col-lg-9 form-check mt-1">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input id="check7" checked="" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label font-14" for="check7">Checkbox /
                                            Selected</label>
                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input id="check8" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label font-14" for="check8">Checkbox /
                                            Default</label>
                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input id="check9" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label font-14" for="check9">Checkbox /
                                            Disabled</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Radio</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <label class="custom-control custom-radio font-14">
                                        <input checked="" class="custom-control-input" name="radio-stacked"
                                               type="radio"><span class="custom-control-label">Radio / Selected</span>
                                    </label>
                                    <label class="custom-control custom-radio font-14">
                                        <input class="custom-control-input" name="radio-stacked" type="radio"><span
                                        class="custom-control-label">Radio / Default</span>
                                    </label>
                                    <label class="custom-control custom-radio font-14">
                                        <input class="custom-control-input" disabled name="radio-stacked"
                                               type="radio"><span class="custom-control-label">Radio / Disabled</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Inline Radio</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <label class="custom-control custom-radio custom-control-inline font-14">
                                        <input checked="" class="custom-control-input" name="radio-inline" type="radio"><span
                                        class="custom-control-label">Radio / Selected</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline font-14">
                                        <input class="custom-control-input" name="radio-inline" type="radio"><span
                                        class="custom-control-label">Radio / Default</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline font-14">
                                        <input class="custom-control-input" name="radio-inline" type="radio"><span
                                        class="custom-control-label">Radio / Disabled</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Switches</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <div class="custom-control custom-switch mb-2">
                                        <input id="customSwitch1" checked class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label" for="customSwitch1">Toggle Active</label>
                                    </div>
                                    <div class="custom-control custom-switch mb-2">
                                        <input id="customSwitch3" class="custom-control-input" type="checkbox">
                                        <label class="custom-control-label" for="customSwitch3">Toggle Default</label>
                                    </div>
                                    <div class="custom-control custom-switch">
                                        <input id="customSwitch2" class="custom-control-input" disabled type="checkbox">
                                        <label class="custom-control-label" for="customSwitch2">Toggle Disabled</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Basic Select</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <select class="custom-select">
                                        <option selected="" value="1">An Item</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Multiple Select</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <select class="custom-select" multiple="">
                                        <option value="1">An Item</option>
                                        <option value="2">A Second Item</option>
                                        <option value="3">A Third Item</option>
                                        <option value="4">A Third Item</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'FormElements',
}
</script>
